.page-team-landing {

	.practice-chairs {

		.large-tile {
			font-size: .8em;			
		}
		.profile-image-link {
			$size: 15em;

			width: $size;
			height: $size;
		}
	}

	.filter-tabs {
		display: none;
	}
}
