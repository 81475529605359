body {
	font-size: .75em;
}

.global-nav {
	position: absolute;

	.nav-links,
	.btn-site-search {
		display: none;
	}
}

.page-hero {
	position: absolute;

	.page-nav {
		display: none;
	}
}

.next-section {
	display: none;
}

.global-footer {
	display: none;
}

.resources-grid {
	.primary-content {
		margin: 0;
		width: 100%;
	}
	.aside-content {
		display: none;
	}
}

.professional-tile {
	font-size: .9em;
}

.connect-section {
	display: none;
}

.external-link {
	display: none;
}
