.page-team-detail {
	.profile-header {
		.social-links {
			display: none;
		}
	}

	.profile-intro {
		.btn-call-to-action {
			display: none;
		}
	}
}
