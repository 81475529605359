.page-resources-landing {
	.sign-up {
		.btn-call-to-action {
			display: none;
		}
	}

	.resource-articles {
		.search-filters {
			display: none;
		}

		.btn-back-to-top {
			display: none;
		}
	}
}
