.page-experience-landing {
	.capability-accordions {
		.accordion-content {
			display: block !important;
		}
	}

	.cta-see-all-capabilities {
		display: none !important;
	}
}
