body {
  font-size: 0.75em;
}

.global-nav {
  position: absolute;
}
.global-nav .nav-links,
.global-nav .btn-site-search {
  display: none;
}

.page-hero {
  position: absolute;
}
.page-hero .page-nav {
  display: none;
}

.next-section {
  display: none;
}

.global-footer {
  display: none;
}

.resources-grid .primary-content {
  margin: 0;
  width: 100%;
}
.resources-grid .aside-content {
  display: none;
}

.professional-tile {
  font-size: 0.9em;
}

.connect-section {
  display: none;
}

.external-link {
  display: none;
}

.page-resources-landing .sign-up .btn-call-to-action {
  display: none;
}
.page-resources-landing .resource-articles .search-filters {
  display: none;
}
.page-resources-landing .resource-articles .btn-back-to-top {
  display: none;
}

.page-experience-landing .capability-accordions .accordion-content {
  display: block !important;
}
.page-experience-landing .cta-see-all-capabilities {
  display: none !important;
}

.page-team-landing .practice-chairs .large-tile {
  font-size: 0.8em;
}
.page-team-landing .practice-chairs .profile-image-link {
  width: 15em;
  height: 15em;
}
.page-team-landing .filter-tabs {
  display: none;
}

.page-team-detail .profile-header .social-links {
  display: none;
}
.page-team-detail .profile-intro .btn-call-to-action {
  display: none;
}

.page-resources-advice .advice-accordion .accordion-content {
  display: block !important;
}

.page-resources-document .document-generator {
  display: none;
}